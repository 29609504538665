<!-- 头部导航 -->
<template>
  <div class="wrap">
    <div class="top_wrap">
      <!-- logo -->
      <div class="top_img">
        <img
          width="176"
          height="41"
          src="./../assets/images/layout/logo1.png"
          alt
        />
        <span></span>
      </div>
      <div class="header_wrap">
        <!-- 菜单 -->
        <div class="top_left_menu">
          <ul class="menu-item">
            <li
              v-for="(item, index) in childrenMenuList"
              :key="index"
              :class="
                $route.path === item.path
                  ? !item.meta.only
                    ? 'hight-light'
                    : ''
                  : ''
              "
              @click="goTopMenu(item.path, item.meta)"
            >
              <template v-if="item.meta.only">
                <i class="el-icon-arrow-left"></i>
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-else>
                <span :class="{ 'no-open': item.meta.open != undefined }">{{
                  item.meta.title
                }}</span>
              </template>
            </li>
          </ul>
        </div>
        <div class="top_right_user">
          <!-- 头像 -->
          <el-popover width="150" trigger="hover" placement="bottom">
            <ul class="head-wrap">
              <li @click="goPage('/me')">
                <!-- <svg-icon iconClass="my"></svg-icon> -->
                <span style="margin-left:10px;cursor: pointer;">我的资料</span>
              </li>
              <li @click="goPage('/login')">
                <!-- <svg-icon iconClass="exit"></svg-icon> -->
                <span style="margin-left:10px;cursor: pointer;">退出登录</span>
              </li>
            </ul>
            <div slot="reference">
              <img :src="company_logo || session_logo" alt />
              <i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
          <!-- 公司名 -->
          <div style="margin-right:30px;" class="company-name-top">
            {{ $store.state.company_name || session_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //头部导航数组
      childrenMenuList: [],
      //el 默认激活
      defaulted: '',
      defaultName: '',
      session_logo: '',
      session_name: '',
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      company_logo: (state) => state.company_logo,
    }),
  },
  //监控data中的数据变化
  watch: {
    $route(to, form) {
      this.getRouteInfo1(to, form)
    },
  },
  //方法集合
  methods: {
    getRouteInfo1() {
      this.$nextTick(() => {
        this.getRouteInfo()
      })
    },
    // 通过路由获取导航栏选项
    /*一开始
     (list[prop].children[key].children && /\/setshow/g.test(path))没有这样的判断 
     后来再设置公司展示又有一层路由嵌套 但是没有样式了
      就正则了一下在页面也有一个 以后还有的话 就很麻烦了
    */
    getRouteInfo() {
      // console.log(this.$route.path)
      this.defaultName = this.$route.path
      var path = this.$router.history.current.path
      let list = this.$router.options.routes
      // console.log(this.$route.meta.index)
      // console.log(path, list, this.$router)
      // (list[prop].children[key].children && /\/setshow/g.test(path))
      console.log('list', path)
      for (var prop in list) {
        if (list[prop].children && list[prop].children.length > 0) {
          for (var key in list[prop].children) {
            if (list[prop].children[key].path === path) {
              //el 当年菜单激活高亮
              // console.log(this.defaulted)
              console.log('路径', list[prop].children, path)
              if (list[prop].children[key].meta.only) {
                this.childrenMenuList.push(list[prop].children[key])
              } else {
                list[prop].children.filter((item) => {
                  if (item.meta && !item.meta.only) {
                    this.childrenMenuList.push(item)
                  }
                })
              }
              console.log('导航aaa', this.childrenMenuList)
            }
          }
        } else if (list[prop].path != '/error') {
          this.childrenMenuList = []
        }
      }
    },
    //跳页面
    goTopMenu(path, meta) {
      // console.log(meta.open)
      if (meta.open === undefined) {
        // console.log(meta.open)
        // console.log(go)
        if (!meta.go && this.$router.history.current.path != path) {
          this.$router.replace(path)
        } else if (meta.go) {
          this.$router.go(meta.go)
        }
      } else {
        //未开放
        const h = this.$createElement
        this.$notify({
          // title: '标题名称',
          message: h('i', { style: 'color: #ff9900' }, '暂未开放，敬请期待'),
          duration: 1000,
        })
      }
    },
    //头像跳转页面
    goPage(path) {
      if (this.$router.history.current.path != path) {
        if (path === '/login') {
          sessionStorage.clear()
          this.$store.state.company_name = ''
          this.$store.state.company_logo = ''
        }
        this.$router.replace(path)
      } else {
        return false
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.getCompanyShowData({ type: 'all' })
    // this.getRouteInfo()
    // console.log(11)
    console.log(this.$store.state)
    console.log(sessionStorage.getItem('logo'))
    this.session_logo = sessionStorage.getItem('logo')
    this.session_name = sessionStorage.getItem('name')
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getRouteInfo()
    console.log(this.$store.state)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
/* 出现滚动条 样式不跳 */
.wrap {
  width: 100%;
}
/* el 导航菜单 下划线 */
// div /deep/ .el-menu.el-menu--horizontal {
//   border: none;
// }
.top_img {
  width: 160px;
  height: 61px;
  vertical-align: middle;
  // background-color: $main_color;
  background-color: #000;
  flex: none;
  text-align: center;
}
.top_img img {
  width: 80%;
  vertical-align: middle;
}
.top_img span {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
.top_wrap {
  height: 61px;
  width: 100%;
  display: flex;
}
.header_wrap {
  width: 100%;
  min-width: 960px;
  display: flex;
  padding-left: calc(100vw - 100%);
  align-content: space-between;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 4px 2px 0px #dcdfe6;
}
.top_left_menu {
  min-width: 600px;
  background-color: red;
  height: 100%;
}
// 头部菜单导航
.menu-item {
  display: flex;
  height: 100%;
  align-items: center;
  // justify-content: space-between;
  background-color: #fff;
  color: #333;
  min-width: 788px;
}
.menu-item li {
  margin-left: 10px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
}
.menu-item li > span {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}
// 菜单下划线
.hight-light {
  color: $main_color;
  &::after {
    content: '';
    width: 100%;
    display: block;
    border: 1px solid $main_color;
    // margin-top: 20px;
  }
}
/* 头部导航右侧弹性排列取反 */
.top_right_user {
  width: 500px;
  height: 61px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* background: red; */
  padding-right: 30px;
}
.top_right_user img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 10px;
  /* background-color: green; */
}
.company-name-top {
  color: $selfColor;
}
// 点击头像
.head-wrap {
  text-align: center;
  color: $selfColor;
  cursor: pointer;
  font-size: $jd_fontSize;
}
.head-wrap li {
  margin: 5px 0;
  border-bottom: 1px solid #f1f1f1;
  padding: 10px 0;
}
.head-wrap li:hover {
  // color: $main_color;
  background-color: #dcdfe6;
}
.head-wrap li:nth-last-child(1) {
  border: none;
}
//未开发功能
.no-open {
  color: #c0c4cc;
}
</style>
