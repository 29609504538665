<!-- 布局页面 -->
<template>
  <div class="wrap">
    <!-- 表单弹出框 -->
    <div class="isPublishFrom" v-if="isPublishForm">
      <PublishFormComponent></PublishFormComponent>
    </div>
    <!-- 头部导航 -->
    <div class="nav_wrap">
      <div class="nav">
        <NavComponent></NavComponent>
      </div>
    </div>
    <!-- 侧边栏 -->
    <div class="sidebar_wrap">
      <div class="sidebar">
        <SidebarComponent></SidebarComponent>
      </div>
    </div>

    <div class="main_wrap">
      <div class="main">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import SidebarComponent from '@/components/sidebar'
import NavComponent from '@/components/topNavigation'

import PublishFormComponent from '@/components/publishForm'
import BottomComponent from '@/components/bottom'

import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SidebarComponent,
    NavComponent,
    PublishFormComponent,
    BottomComponent,
  },
  data() {
    //这里存放数据
    return {
      // isPublishForm:f
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      isPublishForm: (state) => state.message.isPublishForm,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(process.env, 'url')
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
/* //@import url(); 引入公共css类 */
/* .wrap {
min-width: 800px;
} */
.isPublishFrom {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 901;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);
}
.nav_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;

  height: 61px;
  background-color: #fff;
}

.bottom {
}
.sidebar {
  width: 160px;
  height: 100%;
}

.sidebar_wrap {
  width: 160px;
  flex: none;
  position: fixed;
  top: 61px;
  left: 0;
  z-index: 900;
}

.right_wrap {
  flex: none;
  width: 100%;
}

.main_wrap {
  width: calc(100% - 160px);
  margin-left: 160px;
  margin-top: 61px;
}
.main {
  width: 1300px;
  min-width: 1100px;
  margin: auto;
}
</style>
