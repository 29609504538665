<!-- 发布职位表单 -->
<template>
  <div class="wrap-box">
    <!-- <div class="exit" @click="emitPublishForm({ isPublishForm: false})">
      <i class="el-icon-error" style="color:#fff"></i>
      取消
    </div>-->
    <div class="wrap">
      <div class="submit" @click="submit">发布</div>
      <div class="exit" @click="emitPublishForm({ isPublishForm: false })">
        取消
      </div>

      <div class="form_wrap">
        <div>
          <el-form>
            <el-form-item label="职位标题">
              <el-input
                v-model.trim="formData.jd_title"
                placeholder="请输入职位"
              ></el-input>
            </el-form-item>
            <el-form-item label="招聘人数">
              <el-input
                placeholder="请输入人数"
                v-model.trim="formData.jd_people_min"
              ></el-input>
            </el-form-item>
            <el-form-item label="岗位月薪">
              <el-input
                placeholder="请输入月薪"
                v-model.trim="formData.jd_salary_min"
                class="smallIpt"
              ></el-input>
              <span style="padding:0 10px">-</span>
              <el-input
                placeholder="请输入月薪"
                v-model.trim="formData.jd_salary_max"
                class="smallIpt"
              ></el-input>
              <span style="padding-left:10px">元</span>
            </el-form-item>
            <el-form-item label="职位类别">
              <el-cascader
                :options="indry"
                v-model="arrIndustryid"
                @change="handleChangeIndustry"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="学历要求">
              <el-select v-model="value3" placeholder="请选择" @change="ccc">
                <el-option
                  v-for="(item, index) in educationBackground"
                  :key="item.value"
                  :label="item.label"
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="工作经验">
              <el-select v-model="value4" placeholder="请选择">
                <el-option
                  v-for="item in work_time"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位描述">
              <el-input
                type="textarea"
                class="area"
                v-model="formData.jd_desc"
                placeholder="请填写职位描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="工作地点">
              <el-cascader
                :options="cn"
                v-model="arrCnid"
                @change="handleChangeCN"
                :props="{}"
              ></el-cascader>
            </el-form-item>
            <div class="company_environment">
              <el-form-item label="公司环境（1-3张）">
                <el-upload
                  :class="{ disable: imagesMax }"
                  :on-success="successFile"
                  list-type="picture-card"
                  :before-remove="removeHandler"
                  :on-remove="OnremoveHandler"
                  :file-list="fileList"
                  action="/api/kapin_ee/user/v1/files_upload/"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
            <el-form-item label="职位诱惑（1-6个）"> </el-form-item>
        

          <div class="welfare-wrap">
            <span
              :class="{
                choice: true,
                active: item.ischoice == 1 ? true : false,
              }"
              @click="choose(item)"
              v-for="(item, index) in welfare"
              :key="index"
              >{{ item.tag_name }}</span
            >
          </div> 
          <!--开始 -->
           <el-form-item label="是否是否参与悬赏">
                <el-switch
                  active-color="#58362A"
                  v-model="formData.jd_isreward" 
                  active-text="是"
                  inactive-text="否"
                ></el-switch>
            </el-form-item> 
  
 
            <div class="xuanshang " v-if="formData.jd_isreward" >
              <el-form-item label="悬赏佣金：">
                <el-input
                  v-model.trim="formData.jd_money"
                  placeholder="如：200"
                  value="0"
                ></el-input>元
              </el-form-item>

          <el-form-item label="悬赏规则">
              <el-input
                type="textarea"
                class="area"
                v-model="formData.jd_rule"
                value="1.入职5天后结算佣金"
              ></el-input>
            </el-form-item>


            </div>

           <!-- 结束 -->
  </el-form>
          <!-- </div> -->
          <!-- </transition> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  pay,
  welfare,
  educationBackground,
  company_scale,
  work_time,
} from '@/utils/searchtype'
import { mapMutations, mapState, mapActions } from 'vuex'
import { getPublish, getwelfare, getUpdate } from '@/api/tool/publish.js'
import { showCity } from '@/api/me/index.js'
import axios from 'axios'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //薪资
      value1: '',
      valueIndex1: null,
      value2: '',
      valueIndex2: null,
      value3: '',
      valueIndex3: null,
      value4: '',
      valueIndex4: null,
      work_time: [],
      optionsPay: [],
      optionsWelfare: [],
      educationBackground: [],
      company_scale: [],
      //福利待遇
      // welfareIsShow: false,
      welfareIsShowActive: false,
      //动画
      formData: {},
      //已选职位诱惑
      welfare_choosed: [],
      //api职位诱惑
      welfare: [],
      imagesMax: false,
      //从哪里进来的
      updateType: 0,
      //行业

      fileList: [],
      //地区
      cn: [],
      arrCnid: [],
      //行业
      indry: [],
      arrIndustryid: [],
      //是否参与悬赏
      jd_isreward:false,
      jd_money:'',
      jd_rule:''
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      publishInfo: (state) => state.message.publishInfo,
    }),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ccc(val) {
      console.log(val)
      this.formData.jd_edu_id = val
    },
    //弹出表单
    ...mapMutations({
      emitPublishForm: 'message/emitPublishForm',
    }),
    // 获取数据
    ...mapActions({
      getJdData: 'tool/getJD',
    }),
    //选择福利待遇
    choose(item) {
      if (item.ischoice === 1) {
        this.handleClose(item)
      } else {
        if (this.welfare_choosed.length >= 6) {
          this.$message({
            message: '职位诱惑最多可选6个',
            type: 'warning',
          })
          return
        }
        item.ischoice = 1
        this.welfare_choosed.push(item)
      }
    },
    //从已选的福利待遇去掉
    handleClose(self) {
      // console.log(this.welfare_choosed[index].tag_name)
      this.welfare.filter((item) => {
        // console.log(item)
        if (item.tag_name === self.tag_name) {
          item.ischoice = 0

          this.welfare_choosed.filter((item2, index, arr) => {
            if (item2.tag_name === self.tag_name) {
              arr.splice(index, 1)
              return arr
            }
          })
          return item
        }
      })
    },
    //图片上传
    successFile(response, file, fileList) {
      console.log(response, file, fileList)
      this.fileList = fileList
      if (fileList.length >= 3) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //图片删除确定框
    removeHandler(file, fileList) {
      return this.$confirm('是否确定删除此图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功'
          // })
        })
        .catch((e) => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
          return Promise.reject()
        })
    },
    //图片删除
    OnremoveHandler(file, fileList) {
      this.fileList = fileList
      if (this.fileList.length >= 3) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //判断是发布还是更改jd
    submit() {
      if (this.updateType === 0) {
        this.publish()
      } else if (this.updateType === 1) {
        this.update()
      }
    },
    handleChangeCN(value) {
      this.formData.jd_place_id = value[value.length - 1]
      console.log(value)
    },

    handleChangeIndustry(value) {
      this.formData.jd_position_id = value[value.length - 1]
      console.log(value)
    },
    //发布
    publish() {
      //学历
      console.log(this.value3, 'aaaa')
      //学历
      if (this.value3) {
        this.formData.jd_edu_id = Number(this.value3)
      }
      //工作经验
      if (this.value4) {
        this.formData.jd_exp_id = Number(this.value4)
      }
      if (this.welfare_choosed.length > 0) {
        this.formData.jd_tags_id = this.welfare_choosed
          .map((item) => {
            return item.tag_id
          })
          .join(',')
      } else {
        this.formData.jd_tags_id = ''
      }
      if (this.formData.jd_position_id instanceof Array) {
        this.formData.jd_position_id = this.formData.jd_position_id[1]
      }
      this.formData.jd_img = this.fileList
        .map((item) => {
          return item.response.file_id
        })
        .join(',')
      let arrParam = [
        'jd_title',
        // 'jd_phone',
        'jd_position_id',
        'jd_exp_id',
        'jd_edu_id',
        'jd_desc',
        'jd_people_min',
        // 'jd_people_max',
        'jd_salary_min',
        'jd_salary_max',
        'jd_tags_id',
        'jd_img',
        // 'jd_creator',
        // 'jd_tags_name',
        'jd_place_id', 

      ]
      //发布新JD 表单是否为空
      let a = arrParam.some((item) => {
        console.log(item, this.formData[item])
        return this.formData[item] === undefined || this.formData[item] === ''
      })
      if (a) {
        this.$message({
          message: '请完善职位信息',
          type: 'warning',
        })
      } else {
        let peoplemin = /^\d+$/g.test(this.formData.jd_people_min)
        let moneymin = /^\d+$/g.test(this.formData.jd_salary_min)
        let moneymax = /^\d+$/g.test(this.formData.jd_salary_max)
        if (!(peoplemin && moneymin && moneymax)) {
          this.$message({
            message: '请输入正确的招聘人数和薪资',
            type: 'warning',
          })
          return
        }

        if (
          Number(this.formData.jd_salary_min) >=
          Number(this.formData.jd_salary_max)
        ) {
          this.$message({
            message: '请输入正确的薪资',
            type: 'warning',
          })
          return
        }

        this.formData.jd_people_min = Number(this.formData.jd_people_min)
        this.formData.jd_salary_min = Number(this.formData.jd_salary_min)
        this.formData.jd_salary_max = Number(this.formData.jd_salary_max)
        this.formData.jd_people_max = this.formData.jd_people_min

        this.formData.jd_isreward =this.formData.jd_isreward ?1:0

        getPublish(this.formData).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.emitPublishForm({ isPublishForm: false })
            this.getJdData()
            this.$message({
              message: '发布成功',
              type: 'success',
            })
          }else{
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
        })
      }
    },
    /*更新
    有些直接传过来的不是下标 需要转一下 this.valueIndex1 在获取的时候赋值的
    */
    update() {
      let value1, value2, value3, value4

      if (typeof this.value3 === 'string' && isNaN(this.value3)) {
        value3 = this.valueIndex3
      } else {
        value3 = this.value3
      }
      if (typeof this.value4 === 'string' && isNaN(this.value4)) {
        value4 = this.valueIndex4
      } else {
        value4 = this.value4
      }
      if (Array.isArray(this.arrCnid)) {
        this.formData.jd_place_id = this.arrCnid[this.arrCnid.length - 1]
      }

      //学历
      if (this.value3) {
        this.formData.jd_edu_id = Number(value3)
      }
      //工作经验
      if (this.value4) {
        this.formData.jd_exp_id = Number(value4)
      }

      if (this.welfare_choosed.length > 0) {
        this.formData.jd_tags_id = this.welfare_choosed
          .map((item) => {
            return item.tag_id
          })
          .join(',')
      } else {
        this.formData.jd_tags_id = ''
      }
      this.formData.jd_img = this.fileList
        .map((item) => {
          console.log(item)
          if (item.fild_id) {
            return item.fild_id
          } else if (item.response) {
            return item.response.file_id
          }
        })
        .join(',')
      console.log(this.formData.jd_img)
      console.log(this.formData.jd_place_id)
      if (this.formData.jd_position_id instanceof Array) {
        this.formData.jd_position_id = this.formData.jd_position_id[
          this.formData.jd_position_id.length - 1
        ]
      }
      let arrParam = [
        'jd_title',
        // 'jd_phone',
        'jd_position_id',
        'jd_exp_id',
        'jd_edu_id',
        'jd_desc',
        'jd_people_min',
        // 'jd_people_max',
        'jd_salary_min',
        'jd_salary_max',
        'jd_tags_id',
        // 'jd_creator',
        // 'jd_tags_name',
        'jd_place_id', 
      ]
      //发布新JD 表单是否为空
      let a = arrParam.some((item) => {
        console.log(item)
        return this.formData[item] === undefined || this.formData[item] === ''
      })
      if (a) {
        this.$message({
          message: '请完善职位信息',
          type: 'warning',
        })
      } else {
        let peoplemin = /^\d+$/g.test(this.formData.jd_people_min)
        let moneymin = /^\d+$/g.test(this.formData.jd_salary_min)
        let moneymax = /^\d+$/g.test(this.formData.jd_salary_max)
        if (!(peoplemin && moneymin && moneymax)) {
          this.$message({
            message: '请输入正确的招聘人数和薪资',
            type: 'warning',
          })
          return
        }
        if (this.formData.jd_salary_min >= this.formData.jd_salary_max) {
          this.$message({
            message: '请输入正确的薪资',
            type: 'warning',
          })
          return
        }

        this.formData.jd_people_min = Number(this.formData.jd_people_min)
        this.formData.jd_salary_min = Number(this.formData.jd_salary_min)
        this.formData.jd_salary_max = Number(this.formData.jd_salary_max)
        this.formData.jd_people_max = this.formData.jd_people_min
        console.log(this.formData)
        let arrParam = [
          'jd_title',
          // 'jd_phone',
          'jd_position_id',
          'jd_exp_id',
          'jd_edu_id',
          'jd_desc',
          'jd_people_min',
          'jd_people_max',
          'jd_salary_min',
          'jd_salary_max',
          'jd_tags_id',
          'jd_creator',
          // 'jd_tags_name',
          'jd_place_id',
          'jd_img',
          'jd_isreward',
        'jd_money',
        'jd_rule'
        ]
        let id = this.formData.id
        let data = {}
        for (var prop in this.formData) {
          if (arrParam.indexOf(prop) >= 0) {
            data[prop] = this.formData[prop]
          }
        }
        console.log(data)
        data.jd_isreward =data.jd_isreward ?1:0

        getUpdate({ id: id, update_data: data }).then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.emitPublishForm({ isPublishForm: false })
            this.getJdData()
            this.$message({
              message: res.msg,
              type: 'success',
            })
          }
        })
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    showCity({}).then((res) => {
      console.log(res)
      this.cn = res.data
    })

    getwelfare().then((res) => {
      // console.log(res)
      if (res.code === 0) {
        this.welfare = res.data.map((item) => {
          item.ischoice = 0 
          return item
        })
      }
      if (this.publishInfo) {
        console.log("-------------------")
        console.log(this.publishInfo)

        this.formData = this.publishInfo

        this.updateType = 1
        //图片
        this.fileList = this.formData.jd_img_data.map((item) => {
          return {
            url: item,
          }
        })
        //诱惑
        if (this.formData.jd_img_file_id != '') {
          this.formData.jd_img_file_id.split(',').filter((item, index) => {
            this.fileList[index].fild_id = item
          })
        }

        //悬赏
        
         this.formData.jd_isreward =  this.formData.jd_isreward ==1?true:false

        //图片逻辑 超过隐藏样式
        if (this.fileList.length >= 3) {
          this.imagesMax = true
        } else {
          this.imagesMax = false
        }
        


        console.log(this.fileList)
        if (this.formData.jd_tags_id) {
          let arr = this.formData.jd_tags_id.split(',')
          console.log(arr)
          arr.filter((item) => {
            console.log('1112')
            this.welfare.filter((item1) => {
              // console.log(item)
              if (item1.tag_id == item) {
                item1.ischoice = 1
                this.welfare_choosed.push(item1)
              }
            })
          })
        }
        // 人数1 月薪2 学历3 工作经验4
        console
        this.arrCnid = [
          this.formData.jd_place_data.province_code,
          this.formData.jd_place_data.city_code,
          this.formData.jd_place_data.district_code,
        ]

        this.arrIndustryid = [
          Number(this.formData.jd_position_data.first_position_id),
          Number(this.formData.jd_position_data.second_position_id),
          Number(this.formData.jd_position_data.third_position_id),
        ]
        // console.log(this.formData.jd_position_data, this.arrIndustryid)
        this.value3 = this.educationBackground[this.publishInfo.jd_edu_id].label
        this.valueIndex3 = this.publishInfo.jd_edu_id
        this.value4 = this.work_time[this.publishInfo.jd_exp_id].label
        this.valueIndex4 = this.publishInfo.jd_exp_id
      } else {
        this.updateType = 0
      }
    })
    axios.get('/static/indry.json').then((res) => {
      console.log(res)
      this.indry = res
    })
    this.optionsPay = pay
    this.company_scale = company_scale
    this.educationBackground = educationBackground
    this.work_time = work_time
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(this.publishInfo)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap-box {
  position: absolute;
  width: 970px;
  /* height: 100%; */
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.wrap {
  overflow-x: hidden;
  height: 100%;
  width: 970px;
  margin: auto;
  overflow-y: auto;
  background-color: #fff;
}
.form_wrap {
  /* width: 970px; */
  /* height: 80vh; */
  height: calc(100% - 50px);
  padding-left: 30px;
  padding-top: 50px;
}

/* 修改input */
div /deep/ .el-form {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}
/* div /deep/ .el-form:after {
  display: block;
  content: '';
  width: 50%;
  height: 0px;
} */
div /deep/ .el-form-item {
  width: 50%;
}
div /deep/ .el-input {
  width: 300px;
}
.company_environment {
  width: 100%;
}
.company_environment /deep/ .el-form-item {
  width: 100%;
}
/* 福利 */
/* .welfare_choosed_wrap /deep/ .el-tag {
  margin: 7px;
  display: inline-block;
}
.abc-enter-active {
  animation: abc 1s;
}
.abc-leave-active {
  animation: cba 1s;
} */
.welfare-wrap {
  /* width: 900px; */
  min-height: 150px;
  margin: -15px auto 0;
  white-space: wrap;
  background-color: #fff;
  /* animation: abc 1s; */
}
@keyframes cba {
  0% {
    left: 0px;
    opacity: 1;
  }
  100% {
    left: 220px;
    opacity: 0;
  }
}
@keyframes abc {
  0% {
    left: 220px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
/* .welfare-wrap  */
.choice {
  display: inline-block;
  margin: 7px 7px 7px 7px;
  /* margin-right: 10px; */
  padding: 0 10px;
  border: 1px dashed #ccc;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  line-height: 18px;
  /* position: relative; */
}
.choice.active {
  border: 1px solid $main2_color;
}
/* 发布 */
.submit {
  position: absolute;
  text-align: center;
  line-height: 40px;
  top: -5px;
  right: -40px;
  width: 100px;
  height: 40px;
  z-index: 300;
  background-color: $main4_color;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
}
/* 取消
 */
.exit {
  position: absolute;
  text-align: center;
  line-height: 40px;
  top: 40px;
  right: -40px;
  width: 100px;
  height: 40px;
  z-index: 300;
  border-radius: 4px;
  background-color: $main4_color;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.area {
  width: 300px;
}
.area /deep/ .el-textarea__inner {
  display: inline-block;

  min-height: 150px !important;
  font-family: auto;
}
.disable /deep/ .el-upload--picture-card {
  display: none;
}
.smallIpt {
  width: 120px !important;
}
div /deep/ .el-form-item__label::before {
  content: '*';
  /* position: absolute; */
  padding-right: 6px;
  color: $main5_color;
}

.xuanshang{
  clear: both;    width: 100%;
}

 
</style>
