<!-- 侧边栏 -->
<template>
  <div class>
    <el-menu
      :default-active="defaultLight"
      class="el-menu-vertical-demo"
      background-color="#000"
      text-color="#63676f"
      active-text-color="#fff"
    >
      <el-menu-item
        v-for="(item, index) in menuList"
        :key="index"
        :index="item.title"
        @click="goMenu(item.to)"
      >
        <i :class="item.icon" style="margin-right: 0.7em"></i>

        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import "./../assets/css/iconfont.css";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      now: "/company",
      menuList: [],
      defaultLight: "",
      ac: "14px",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route: "default",
  },
  //方法集合
  methods: {
    /*el 侧边栏激活高亮
    存到session  直接url 高亮会出问题
    */
    default() {
      console.log(
        "sessionStorage.getItem",
        sessionStorage.getItem("is_admin") == 1
      );
      if (sessionStorage.getItem("is_admin") == 1) {
        this.menuList = [
          {
            title: "公司展示",
            icon: "iconfont icongongsi",
            to: "/company",
            show: false,
          },
          {
            title: "招聘工具",
            icon: "iconfont icongongju",
            to: "/tool",
            show: false,
          },
          {
            title: "猎场",
            icon: "iconfont iconliechang",
            to: "/resume",
            show: false,
          },
          {
            title: "聊一聊",
            icon: "iconfont iconliechang",
            to: "/chatlist",
            show: false,
          },
          {
            title: "推广互动",
            icon: "iconfont iconfenxiang",
            to: "/promotion",
            show: false,
          },
          {
            title: "我的",
            icon: "iconfont iconme",
            to: "/me",
            show: false,
          },
          {
            title: "数据看板",
            icon: "iconfont iconshuju",
            to: "/data_board",
            show: false,
          },
          {
            title: "账户管理",
            icon: "iconfont iconzhanghaoguanli",
            to: "/add_user",
            show: false,
          },
          {
            title: "经纪人管理",
            icon: "iconfont iconzhanghaoguanli",
            to: "/search_agent",
            show: false,
          },
          {
            title: "聊一聊管理",
            icon: "iconfont iconzhanghaoguanli",
            to: "/chatlist_search",
            show: false,
          },
          {
            title: "特殊线索",
            icon: "iconfont iconshuju",
            to: "/tsxiansuo",
            show: false,
          },
          {
            title: "积分管理",
            icon: "iconfont iconshuju",
            to: "/search_jifen",
            show: false,
          },
          {
            title: "积分商城",
            icon: "iconfont iconme",
            to: "/shop_goods",
            show: false,
          },
          {
            title: "导出职位",
            icon: "iconfont iconshuju",
            to: "/daochu_jd",
            show: false,
          },
        ];
      } else if (sessionStorage.getItem("is_admin") == 2) {
        this.menuList = [
          {
            title: "公司展示",
            icon: "iconfont icongongsi",
            to: "/company",
            show: false,
          },
          {
            title: "招聘工具",
            icon: "iconfont icongongju",
            to: "/tool",
            show: false,
          },
          {
            title: "猎场",
            icon: "iconfont iconliechang",
            to: "/resume",
            show: false,
          },
          {
            title: "聊一聊",
            icon: "iconfont iconliechang",
            to: "/chatlist",
            show: false,
          },
          {
            title: "推广互动",
            icon: "iconfont iconfenxiang",
            to: "/promotion",
            show: false,
          },
          {
            title: "我的",
            icon: "iconfont iconme",
            to: "/me",
            show: false,
          },
          {
            title: "积分商城",
            icon: "iconfont iconme",
            to: "/shop_goods",
            show: false,
          },
          {
            title: "未简线索",
            icon: "iconfont iconshuju",
            to: "/city_table?src=未简雷达",
            show: false,
          },
          {
            title: "特殊线索",
            icon: "iconfont iconshuju",
            to: "/tsxiansuo",
            show: false,
          },
        ];
      } else {
        this.menuList = [
          {
            title: "公司展示",
            icon: "iconfont icongongsi",
            to: "/company",
            show: false,
          },
          {
            title: "招聘工具",
            icon: "iconfont icongongju",
            to: "/tool",
            show: false,
          },
          {
            title: "猎场",
            icon: "iconfont iconliechang",
            to: "/resume",
            show: false,
          },
          {
            title: "聊一聊",
            icon: "iconfont iconliechang",
            to: "/chatlist",
            show: false,
          },
          {
            title: "推广互动",
            icon: "iconfont iconfenxiang",
            to: "/promotion",
            show: false,
          },
          {
            title: "我的",
            icon: "iconfont iconme",
            to: "/me",
            show: false,
          },
          {
            title: "经纪人管理",
            icon: "iconfont iconzhanghaoguanli",
            to: "/agent_company",
            show: false,
          },
          {
            title: "积分商城",
            icon: "iconfont iconme",
            to: "/shop_user_goods",
            show: false,
          },
        ];
      }
      console.log(
        "dddddddddddddddddddddd",
        sessionStorage.getItem("isduihuan"),
        sessionStorage.getItem("is_admin")
      );
      this.menuList.filter((item, index) => {
        if (item.to === this.$route.meta.parentUrl) {
          this.defaultLight = item.title;
          item.show = true;
        } else {
          item.show = false;
        }
        if (
          sessionStorage.getItem("isduihuan") == 1 &&
          sessionStorage.getItem("is_admin") == 0
        ) {
          console.log(item.title, " index=", index);
          if (item.title == "积分商城") {
            console.log(item.title);
            this.menuList.pop(item);
          }
        }
      });

      console.log(this.menuList);
    },
    // 跳页面
    goMenu(path) {
      if (this.$router.history.current.path != path) {
        this.$router.replace(path);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.default();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* //@import url(); 引入公共css类 */
/* ul /deep/ .el-menu-item:nth-child(1) {
  text-align: center;
} */
.el-menu {
  width: 160px;
  height: calc(100vh - 61px);
}
/* .menu_img_wrap {
  height: 90px;
  margin-top: 30px;
  vertical-align: middle;
} */
/* .menu_imgStyle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  vertical-align: middle;
} */
.menu_img_wrap span {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
</style>
